import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import media from "styled-media-query";

import Main from "../components/Main";

import { PageHeader } from "../components/Typo";
import QuoteBox from "../components/QuoteBox";
import MediaBottom from "../components/MediaBottom";

import band from "../images/band.jpg";

import Img from "gatsby-image";
// eslint-disable-next-line
import { squareImage } from "../image";

const Bio = styled.div`
  max-width: 960px;
  margin: auto;

  p {
    padding: 0px 16px;
  }

  .feature-image {
    margin: 32px 16px;

    ${media.lessThan("medium")`
      margin: 32px auto;
    `}
  }

  ${media.greaterThan("medium")`
    columns: 2;
    column-gap: 76px;

    & .before-image {
      /* margin-bottom: 64px; */
    }
  
  `}
`;

export default () => {
  const data = useStaticQuery(graphql`
    query {
      photo: file(relativePath: { eq: "8356-2.jpg" }) {
        ...squareImage
      }
    }
  `);

  const imageData = data.photo;

  return (
    <Main title="Wie is Emma Lou?" path="/wie">
      <PageHeader title="Wie is Emma Lou?" icon="id" />
      <Bio>
        <p style={{ marginTop: 0 }}>
          Emma Lou (Emma Paalvast) maakt muziek recht uit het hart en 100%
          autobiografisch. De Amersfoortse onderscheidt zich door goudeerlijke
          teksten, een gezonde dosis sarcasme én staat altijd met beide benen op
          de grond. Haar rijke gevoelsleven, verwachtingen, teleurstellingen,
          maar ook de dood vormen een inspiratiebron voor haar muziek.
          <br />
          Dat haar stijl aanslaat, bewijst een uitverkocht Theater De Lieve
          Vrouw in haar thuisstad. Van rustige luisterliedjes tot uptempo
          popsongs, herkenbare teksten die raken en vermaken. Niet voor niets
          won Emma Lou de publieksprijs van het Utrechts Kleinkunst Festival.
        </p>
        <p>
          Na een succesvol debuutalbum en theatertour is de
          multi-instrumentaliste in 2022 terug met nieuwe muziek: een nieuw
          geluid maar helemaal Emma Lou. Op dit moment schrijft ze voor haar
          nieuwe EP, die in 2023 wordt verwacht.
        </p>
        <p>
          <img alt="live" src={band} width="100%" style={{ marginTop: 12 }} />
        </p>
        <p className="before-image">
          In 2016 verscheen haar debuutalbum 'Nooit Alleen'; een heuse
          theatervoorstelling in je huiskamer. Op het conceptalbum geeft Emma
          Lou zich bloot, met een dikke knipoog én waar nodig een cynische
          ondertoon. De theatrale overgangen maken het album - dat niet mag
          ontbreken in de platenkast van de Nederlandse popliefhebber -
          compleet.
          <br />
          Hieruit volgde de muziektheatervoorstelling 'Emma WHO?!' waarmee ze in
          2017-2018 door Nederland toerde. Dit avondvullende programma werd zeer
          goed ontvangen in o.a. een bommetje vol Herman van Veen Arts Center.
        </p>

        <Img
          className="feature-image"
          fluid={imageData.childImageSharp.fluid}
          alt={""}
          fadeIn={true}
          durationFadeIn={100}
        />

        <p>
          Dat Emma Lou zelf artiest zou worden zat er al jong in. Ze groeide op
          tussen de gitaren en violen. Dat laatste instrument speelt nog steeds
          een belangrijke rol in haar repertoire. Na jaren klassiek les te
          hebben gehad, rondde de muzikante zowel het conservatorium van Zwolle
          als Arnhem af. Daarmee is ze volleerd docent én jazz- en popzangeres.
          Tijdens deze studies richtte ze zich verder volledig op songwriting.
          De combinatie van talenten resulteerde in 2014 in de
          afstudeervoorstelling ‘Alleen Gelukkig’, waar ze de ArtEZ
          Vrienden-Publieksprijs mee in de wacht sleepte.
        </p>

        <QuoteBox
          inline
          padding={"16px 32px"}
          quote={`“Emma Lou schreed het podium op als een ware diva, begeleid door een zeer muzikaal combo en zelfs een strijkkwartet”`}
        />

        <p>
          schreef een overdonderde jury destijds. Sindsdien is Emma Lou een
          publiekslieveling bij jong en oud.
        </p>
      </Bio>

      <MediaBottom />
    </Main>
  );
};
